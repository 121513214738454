@use "@nationwide-bolt/css-core/dist/scss/tokens" as Bolt;

@import "@nationwide-bolt/css-core/dist/css/bolt-core";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.error-message {
    color: red;
}

bolt-notification ul.error-list {
    margin-bottom: 0;
    margin-left: 2em;
    margin-top: 0.4em;

    li {
        margin-bottom: 0.3em;
    }
}

.content-block {
    display: block;
}

.flex-wrap-container {
    display: flex;
    flex-wrap: wrap;
}

.text-no-wrap {
    white-space: nowrap;
}

.margin-top-sm {
    margin-top: Bolt.$space-xs;
}

.margin-top-n-md {
    margin-top: -12px;
}

.margin-top-n-xl {
    margin-top: -32px;
}

.left-margin-103 {
    margin-left: 103px;
}

.element-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    text-transform: uppercase;
}

.margin-bottom-auto {
    margin-bottom: auto;
}

.padding-top-lg {
    padding-top: Bolt.$space-lg;
}

.divider {
    border-right: 1px solid;
}

.margin-bottom-lg {
    margin-bottom: Bolt.$space-lg;
}

@media print {
    .noprint {
        display: none;
    }

    .print-width {
        width: 100%;
    }

    .print-padding-left-20 {
        padding-left: Bolt.$space-lg;
    }

    .print-padding-bottom-x1 {
        padding-bottom: Bolt.$space-2xl;
    }

    .print-padding-left-lg {
        padding-left: Bolt.$space-lg;
    }

    .print-padding-left-3x1 {
        padding-left: 84px;
    }

    .print-padding-content {
        padding: 0 Bolt.$space-lg Bolt.$space-2xl Bolt.$space-2xl;
    }

    .border-solid-confirmation {
        border-style: solid;
        border-width: thin;
        margin-top: Bolt.$space-2xl;
    }

    .border-bottom-header {
        border-bottom: solid;
        border-width: thin;
    }

    .padding-header-x1 {
        padding-bottom: Bolt.$space-2xl;
        padding-top: Bolt.$space-2xl;
    }

    .padding-header-md {
        padding-bottom: Bolt.$space-xs;
        padding-top: Bolt.$space-xs;
    }

    .section-half-width {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .section-one-third {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }

    .margin-bottom-x1 {
        margin-bottom: Bolt.$space-2xl;
    }

    .margin-top-x1 {
        margin-top: Bolt.$space-2xl;
    }
}

@media screen {
    .noscreen {
        display: none;
    }

    .imgdiv {
        display: flex;
        justify-content: center;
    }

    .global-container {
        height: 100%;
        background-color: Bolt.$palette-white;
    }

    .background-color-white {
        background-color: Bolt.$palette-white;
        margin: 0;
    }

    .border-bottom-footer {
        margin: 0;
    }
}

@media only screen and (max-width: Bolt.$screen-sm) {
    .nomobile {
        display: none;
    }

    .divider-bottom {
        display: block;
        unicode-bidi: isolate;
        margin-block: 0.5em 0.5em;
        margin-inline: auto auto;
        overflow: hidden;
        border-style: inset;
        border-width: 1px;
    }

    .mobile-margin-bottom-lg {
        margin-bottom: Bolt.$space-lg;
    }

    .mobile-padding-bottom-lg {
        padding-bottom: Bolt.$space-lg;
    }

    .mobile-padding-top-lg {
        padding-top: Bolt.$space-lg;
    }

    .divider {
        border-right: 0;
    }
}

@media only screen and (min-width: Bolt.$screen-sm) {
    .margin-bottom-x1 {
        margin-bottom: Bolt.$space-2xl;
    }

    .margin-top-x1 {
        margin-top: Bolt.$space-2xl;
    }

    .large-screen-margin {
        margin-top: Bolt.$space-lg;
        margin-bottom: Bolt.$space-lg;
    }

    .client-img {
        height: 84px;
    }

    .nodesktop {
        display: none;
    }
}

.divider-bulk {
    padding-bottom: 0.8em;
    border-bottom: 1px solid Bolt.$palette-darkGray;
}

.disable-iframe-visibility {
    width: 0;
    height: 0;
    border: 0;
    border: none;
}

@media only screen and (max-width: 375px) {
    .section-full {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: Bolt.$screen-md) and (min-width: 375px) {
    .show-element-md {
        display: block;
    }
}

.bolt-typography .bolt-heading-sm {
    font-weight: bold !important;
}

.align-left {
    text-align: left;
}
